// Place this file in your config directory or suitable location
const gamesConfig = {
  'BeatDecoders': {
    buildFolder: 'WebGLBuilds/BeatDecoders/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/RhythmDecoders.png",
    description: "Learn to make a beat | Discover the 1st element: RHYTHM.",
    displayTitle: "Beat Decoders"
  },
  'HarmonyVoters': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmonyvoters.png",
    description: "Work together to combine harmonies.",
    displayTitle: "Harmony Voters"
  },
  'HarmonyHackers': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmonyhackers.png",
    description: "Write your chords | Discover the 2nd element: HARMONY .",
    displayTitle: "Harmony Hackers"
  },
  'DeliciousTimbre': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/delicioustimbre.png",
    description: "Cook up a feast of instruments | Discover the 3rd element: TIMBRE .",
    displayTitle: "Delicious Timbre"
  },
  'MelodyMystery': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/melodymystery.png",
    description: "Solve the case to make a tune | Discover the 4th element: MELODY .",
    displayTitle: "Melody Mystery"
  },
  'DynamicDefenders': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/dynamicdefenders.png",
    description: "Make your song loud and soft | Discover the 5th element: DYNAMICS .",
    displayTitle: "Dynamic Defenders"
  },
  "Songly": {
    buildFolder: "WebGLBuilds/Songly/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/Songly.png",
    description: "Craft a structured Melody!",
    displayTitle: "Songly: Melodic Form"
  },
    "GravityGame": {
    buildFolder: "WebGLBuilds/GravityGame/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/gravity_game.png",
    description: "A fun brain break!",
    displayTitle: "Gravity Game"
  },
  "InstrumentGuesser1": {
    buildFolder: "WebGLBuilds/InstrumentGuesser1/Build",
    companyName: "Overture Games",
    productVersion: "1.0",
    image: "/images/instrument_guesser.png",
    description: "Work together to guess instruments by listening.",
    displayTitle: "Guess the Instrument!"
  },
  'HarmonyGuesser': {
    buildFolder: 'WebGLBuilds/HarmonyGuesser/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmony_guesser.png",
    description: "Learn the difference between major and minor chords.",
    displayTitle: "Harmony Guesser: Major or Minor?"
  },
  'RhythmRally': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rhythm_rally.png",
    description: "Compete and improve your rhythm memory.",
    displayTitle: "Rhythm Rally"
  }

  };
  
  export default gamesConfig;